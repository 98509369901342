import React from 'react';
import employeeSvg from "../../images/employee.svg";

const Employees = () => {
    return (
        <section className="employees">
            <div className="employees__wrapper wrapper">
                <ul className="employees__list">
                    <li>
                         <div className="employees__image-wrapper">
                             <img src={employeeSvg} alt="Отзыв"/>
                         </div>
                        <div className="employees__content-wrapper">
                            <h4 className="employees__title">Мария</h4>
                            <p className="employees__subtitle">Торговый представитель</p>
                            <p className="employees__text">И с коллективом повезло, и зарплатой не обижают, хотя работы порой бывает через край, особенно в отчетный период.</p>
                        </div>
                    </li>
                    <li>
                         <div className="employees__image-wrapper">
                             <img src={employeeSvg} alt="Отзыв"/>
                         </div>
                        <div className="employees__content-wrapper">
                            <h4 className="employees__title">Евгения</h4>
                            <p className="employees__subtitle">Финансовый менеджер</p>
                            <p className="employees__text">Работаю уже почти полгода консультантом и в принципе все устраивает,
                                хотя есть свои нюансы, но со временем к ним приспособилась.
                                Начальство строгое, но в меру, платят вовремя.
                            </p>
                        </div>
                    </li>
                    <li>
                         <div className="employees__image-wrapper">
                             <img src={employeeSvg} alt="Отзыв"/>
                         </div>
                        <div className="employees__content-wrapper">
                            <h4 className="employees__title">Михаил</h4>
                            <p className="employees__subtitle">Категорийный менеджер</p>
                            <p className="employees__text">Приятно работать в благоустроенном офисе, да и коллектив подобрался неплохой, с некоторыми общаемся и вне работы.
                                Прозрачность в выплатах и расчетах мне нравится, размер зарплаты тоже.
                                Но начальство тут строгое, спустя рукава работать не получится, нужна максимальная отдача
                            </p>
                        </div>
                    </li>
                    <li>
                         <div className="employees__image-wrapper">
                             <img src={employeeSvg} alt="Отзыв"/>
                         </div>
                        <div className="employees__content-wrapper">
                            <h4 className="employees__title">Вадим</h4>
                            <p className="employees__subtitle">Специалист отдела качества</p>
                            <p className="employees__text">Нагрузка большая, тут спору нет, но и должность у меня ответственная - специалист отдела качества,
                                но платят щедро, еще есть возможность покупать сантехнику компании с приличной скидкой, чем я пару раз уже воспользовался.
                            </p>
                        </div>
                    </li>
                    <li>
                         <div className="employees__image-wrapper">
                             <img src={employeeSvg} alt="Отзыв"/>
                         </div>
                        <div className="employees__content-wrapper">
                            <h4 className="employees__title">Светлана</h4>
                            <p className="employees__subtitle">HR маркетолог</p>
                            <p className="employees__text">Мне, наверное, повезло как никому)). И работа классная, и график удобный 5/2,
                                опять же зарплата стабильная и хорошего уровня и при этом я еще и живу рядом, что хорошо экономит время.
                            </p>
                        </div>
                    </li>
                    <li>
                         <div className="employees__image-wrapper">
                             <img src={employeeSvg} alt="Отзыв"/>
                         </div>
                        <div className="employees__content-wrapper">
                            <h4 className="employees__title">Инна</h4>
                            <p className="employees__subtitle">Менеджер по продажам</p>
                            <p className="employees__text">Не на одном месте я успела поработать в своей жизни. В Акваарт уже второй год работаю менеджером по продажам.
                                Товар востребован, поэтому мне приятна моя работа, и приятно помогать людям выбрать хорошую сантехнику.
                            </p>
                        </div>
                    </li>
                    <li>
                         <div className="employees__image-wrapper">
                             <img src={employeeSvg} alt="Отзыв"/>
                         </div>
                        <div className="employees__content-wrapper">
                            <h4 className="employees__title">Андрей</h4>
                            <p className="employees__subtitle">Мастер смены</p>
                            <p className="employees__text">Для реализации себя как специалиста тут есть где развернуться, инициатива поощряется, карьерный рост не быстрый,
                                но он есть, стоит только себя проявить и будешь замечен.
                                Работать в режиме многозадачности - это мое, при этом я решаю проблемы, а не создаю их, за что и ценит меня начальство.
                            </p>
                        </div>
                    </li>
                    <li>
                         <div className="employees__image-wrapper">
                             <img src={employeeSvg} alt="Отзыв"/>
                         </div>
                        <div className="employees__content-wrapper">
                            <h4 className="employees__title">Владимир</h4>
                            <p className="employees__subtitle">Менеджер по работе с клиентами</p>
                            <p className="employees__text">Меня тут держит не только стабильная "белая" заработная плата, но и возможность профессионального роста.
                                В этом плане я обучаем и старателен.
                            </p>
                        </div>
                    </li>
                    <li>
                         <div className="employees__image-wrapper">
                             <img src={employeeSvg} alt="Отзыв"/>
                         </div>
                        <div className="employees__content-wrapper">
                            <h4 className="employees__title">Евгений</h4>
                            <p className="employees__subtitle">Заведующий складом</p>
                            <p className="employees__text">Как в любой работе тут есть свои плюсы и минусы, но плюсов больше, да и привык я уже.
                                Компания развивается, зарплата растет, у начальства на хорошем счету, надеюсь до пенсии тут проработать).
                            </p>
                        </div>
                    </li>
                    <li>
                         <div className="employees__image-wrapper">
                             <img src={employeeSvg} alt="Отзыв"/>
                         </div>
                        <div className="employees__content-wrapper">
                            <h4 className="employees__title">Светлана</h4>
                            <p className="employees__subtitle">Менеджер по продажам</p>
                            <p className="employees__text">Компания солидная, с коллегами нашла общий язык, да и вообще хорошо мне тут работалось,
                                я менеджером по продажам почти год работала, но живу я очень далеко и добираться на работу и обратно крайне тяжело мне,
                                поэтому пришлось, с сожалением, искать работу по-ближе, хотя условия тут и зарплата мне тут очень нравились.
                            </p>
                        </div>
                    </li>
                    <li>
                         <div className="employees__image-wrapper">
                             <img src={employeeSvg} alt="Отзыв"/>
                         </div>
                        <div className="employees__content-wrapper">
                            <h4 className="employees__title">Егор</h4>
                            <p className="employees__subtitle">Менеджер по маркетингу</p>
                            <p className="employees__text">Опыт работы по продвижению и оптимизации сайтов у меня не один год, поэтому начать работать менеджером по маркетингу в Акваарт мне было не сложно.
                                Коллектив не собачится, на первых порах объяснили что да как.
                                Если от работы не отлынивать, то все будет хорошо, ведь зарплата тут хорошая, сколько тут работаю - не разу задержек не было.
                            </p>
                        </div>
                    </li>
                    <li>
                         <div className="employees__image-wrapper">
                             <img src={employeeSvg} alt="Отзыв"/>
                         </div>
                        <div className="employees__content-wrapper">
                            <h4 className="employees__title">Елена</h4>
                            <p className="employees__subtitle">Финансовый менеджер</p>
                            <p className="employees__text">Работа как работа, на зарплату не жалуюсь. В будни работаю, в выходные отдыхаю, не выдергивают.
                                Не давно в отпуске была, тут с этим проблем нет, полный соц.пакет.
                            </p>
                        </div>
                    </li>
                    <li>
                         <div className="employees__image-wrapper">
                             <img src={employeeSvg} alt="Отзыв"/>
                         </div>
                        <div className="employees__content-wrapper">
                            <h4 className="employees__title">Светлана</h4>
                            <p className="employees__subtitle">Менеджер по подбору персонала</p>
                            <p className="employees__text">За год работы в Акваарт я и деньжат неплохо заработала, и опыт хороший приобрела.
                                Нравится лояльное отношение руководства и то что нет напряга по поводу прививок - это дело тут добровольное, ни кого не принуждают.
                            </p>
                        </div>
                    </li>
                    <li>
                         <div className="employees__image-wrapper">
                             <img src={employeeSvg} alt="Отзыв"/>
                         </div>
                        <div className="employees__content-wrapper">
                            <h4 className="employees__title">Андрей</h4>
                            <p className="employees__subtitle">Системный администратор</p>
                            <p className="employees__text">Трудоголиков тут любят, я сам из таких поэтому работаю с удовольствием, при этом и зарплата у меня всегда во время.
                                Нарушений трудовой дисциплины с моей стороны нет, поэтому и порицаний в мой адрес тоже нет. Работу свою знаю и дорожу ей, у начальства на хорошем счету.
                            </p>
                        </div>
                    </li>
                    <li>
                         <div className="employees__image-wrapper">
                             <img src={employeeSvg} alt="Отзыв"/>
                         </div>
                        <div className="employees__content-wrapper">
                            <h4 className="employees__title">Ксения</h4>
                            <p className="employees__subtitle">Менеджер по продажам</p>
                            <p className="employees__text">Официальное оформление после собеседования, комфортные условия работы,
                                полный соц.пакет, поэтому даже если и бывают переработки, то это все компенсируют хорошей зарплатой и если надо я могу и позадержаться.
                                Работаем в коллективе слаженно, руководство адекватное всегда идет на контакт.
                            </p>
                        </div>
                    </li>
                    <li>
                         <div className="employees__image-wrapper">
                             <img src={employeeSvg} alt="Отзыв"/>
                         </div>
                        <div className="employees__content-wrapper">
                            <h4 className="employees__title">Владимир</h4>
                            <p className="employees__subtitle">Региональный менеджер по продажам</p>
                            <p className="employees__text">На должности регионального менеджера с прошлого года работаю.
                                Веду переговоры, заключаю договора, отслеживаю товарные запасы, до хрена работы до хрена,
                                но я знал куда шел, да и зарплата стимулирует к работе, еще оплатчивают ГСМ и корпоративную свзь, в общем условия работы комфортные.
                            </p>
                        </div>
                    </li>
                    <li>
                         <div className="employees__image-wrapper">
                             <img src={employeeSvg} alt="Отзыв"/>
                         </div>
                        <div className="employees__content-wrapper">
                            <h4 className="employees__title">Вадим</h4>
                            <p className="employees__subtitle">Менеджер розничных продаж</p>
                            <p className="employees__text">Работаю по трудовому кодексу, социальный пакет, хорошая своевременная заработная плата.
                                Наличие карьерного роста стимулирует к обучению и старанию.
                            </p>
                        </div>
                    </li>
                    <li>
                         <div className="employees__image-wrapper">
                             <img src={employeeSvg} alt="Отзыв"/>
                         </div>
                        <div className="employees__content-wrapper">
                            <h4 className="employees__title">Илья</h4>
                            <p className="employees__subtitle">Финансовый менеджер</p>
                            <p className="employees__text">Тут стоит потрудиться, если хочешь иметь стабильную работу и зарплату.
                                Начальство строгое, но объективное, за зря замечаний не делает, а вот условия работы предоставляют комфортные.
                            </p>
                        </div>
                    </li>
                    <li>
                         <div className="employees__image-wrapper">
                             <img src={employeeSvg} alt="Отзыв"/>
                         </div>
                        <div className="employees__content-wrapper">
                            <h4 className="employees__title">Дмитрий</h4>
                            <p className="employees__subtitle">Менеджер по продажам</p>
                            <p className="employees__text">Собеседование прошел с легкостью, собственно и взяли меня на вакансию менеджера, а опыт у меня в этом был и тут я себя уже успел показать с хорошей стороны.
                                С коллегами ладим, корпоративы проходят в дружеской атмосфере.
                                Начальство в целом лояльное, но бывают свои загоны, впрочеем как и везде.
                            </p>
                        </div>
                    </li>
                    <li>
                         <div className="employees__image-wrapper">
                             <img src={employeeSvg} alt="Отзыв"/>
                         </div>
                        <div className="employees__content-wrapper">
                            <h4 className="employees__title">Анна</h4>
                            <p className="employees__subtitle">Специалист по качеству</p>
                            <p className="employees__text">Корпоративная политика компании мне пришлась по душе, тружусь я здесь специалистом по качеству почти три года, о смене работы не думаю.
                                Текучки тут как таковой нет потому как работа хорошая и зарплата тоже.
                            </p>
                        </div>
                    </li>
                    <li>
                         <div className="employees__image-wrapper">
                             <img src={employeeSvg} alt="Отзыв"/>
                         </div>
                        <div className="employees__content-wrapper">
                            <h4 className="employees__title">Виктор</h4>
                            <p className="employees__subtitle">Сборщик продукции</p>
                            <p className="employees__text">Я человек новый в компании, сборщиком устроился, недавно первую зарплату получил.
                                По нраву что сотрудники не конфликтные, развозка на корпоративном такси - это хорошо придумано.
                            </p>
                        </div>
                    </li>
                    <li>
                         <div className="employees__image-wrapper">
                             <img src={employeeSvg} alt="Отзыв"/>
                         </div>
                        <div className="employees__content-wrapper">
                            <h4 className="employees__title">Елена</h4>
                            <p className="employees__subtitle">Оператор 1С</p>
                            <p className="employees__text">Много задач на мне как на операторе 1С, но я ними успешно справляюсь, при этом имею оклад и премию, есть возможность дополнительного заработка в выходные дни.
                                Скользящий график работы позволяет решить все свои дела в будние дни, поэтому в выходные иногда выхожу на подработку. Отношения в коллективе нормальное, с начальством тоже.
                            </p>
                        </div>
                    </li>
                    <li>
                         <div className="employees__image-wrapper">
                             <img src={employeeSvg} alt="Отзыв"/>
                         </div>
                        <div className="employees__content-wrapper">
                            <h4 className="employees__title">Александр</h4>
                            <p className="employees__subtitle">Руководитель разработки</p>
                            <p className="employees__text">Не так просто было сюда устроиться.
                                Сначала собеседование, потом испытательный срок, а вот потом уже все плюшки - официальное оформление + зарплата + премия.
                                Пятидневный рабочий график, причем в пятницу трудовой день на час короче. В выходные отдыхаю с семьей.
                            </p>
                        </div>
                    </li>
                    <li>
                         <div className="employees__image-wrapper">
                             <img src={employeeSvg} alt="Отзыв"/>
                         </div>
                        <div className="employees__content-wrapper">
                            <h4 className="employees__title">Ирина</h4>
                            <p className="employees__subtitle">Торговый представитель</p>
                            <p className="employees__text">Работаю в Акваарт давно, уже немного поднялась по карьерной лестнице, тут такая возможность есть, перспектива развития и обучения тоже нет. А еще нравится, что нет принудиловки по вопросу вакцинации.
                                Тут каждый решает сам. Я вот сделала прививку, тем самым и себя обезопасила от вируса и еще поощрение в виде ДМС получила. Спаибо руководству за заботу о сотрудниках.
                            </p>
                        </div>
                    </li>
                    <li>
                         <div className="employees__image-wrapper">
                             <img src={employeeSvg} alt="Отзыв"/>
                         </div>
                        <div className="employees__content-wrapper">
                            <h4 className="employees__title">Светлана</h4>
                            <p className="employees__subtitle">Офис менеджер</p>
                            <p className="employees__text">Работаю офис менеджером с 2019г. Официально оформлена с первого рабочего дня.
                                Условия работы прекрасные, чай кофе за счет компании, есть оборудованная кухня.
                                В моей работе важна стрессоустойчивость и ответственность и я со своими обязанностями справляюсь.
                                Зарплату конечно хочется побольше, но хорошо, что платят без задержек и штрафов нет.
                            </p>
                        </div>
                    </li>
                    <li>
                         <div className="employees__image-wrapper">
                             <img src={employeeSvg} alt="Отзыв"/>
                         </div>
                        <div className="employees__content-wrapper">
                            <h4 className="employees__title">Марина</h4>
                            <p className="employees__subtitle">Бухгалтер</p>
                            <p className="employees__text">Как бухгалтер я работаю с огромным количеством информации, на мне большая ответственность и я стараюсь изо всех сил, лентяи тут не задерживаются.
                                В такой солидной фирме мне ранее не доводилось работать, да и зарплата радует.
                                Хорошо, что у меня был солидный опыт работы и я досконально знаю в программе 1С и Excel, поэтому меня и взяли.
                            </p>
                        </div>
                    </li>
                    <li>
                         <div className="employees__image-wrapper">
                             <img src={employeeSvg} alt="Отзыв"/>
                         </div>
                        <div className="employees__content-wrapper">
                            <h4 className="employees__title">Алексей</h4>
                            <p className="employees__subtitle">Руководитель разработки</p>
                            <p className="employees__text">Начальству в порыве гнева на глаза попадаться не стоит, а в целом обстановка спокойная, располагает к полноценной работе.
                            </p>
                        </div>
                    </li>
                    <li>
                         <div className="employees__image-wrapper">
                             <img src={employeeSvg} alt="Отзыв"/>
                         </div>
                        <div className="employees__content-wrapper">
                            <h4 className="employees__title">Катерина</h4>
                            <p className="employees__subtitle">Продавец-консультант</p>
                            <p className="employees__text">Работа консультантом не пыльная, но порой такие покупатели попадаются,
                                что все нервы вымотают, но хорошо, что не чатсо такое бывает. С выплатой зарплаты проблем нет, коллектив дружный, взаимовыручка присутствует.
                            </p>
                        </div>
                    </li>
                    <li>
                         <div className="employees__image-wrapper">
                             <img src={employeeSvg} alt="Отзыв"/>
                         </div>
                        <div className="employees__content-wrapper">
                            <h4 className="employees__title">Антон</h4>
                            <p className="employees__subtitle">Менеджер по подбору персонала</p>
                            <p className="employees__text">Недавно устроился, пока все нравится. По объявлению на сайте нашел работу, на должность менеджера по подбору персонала меня взяли после собеседования,
                                пока на испытательном сроке.
                                Вообще у меня большой послужной список, надеюсь смогу тут сделать себе карьеру, уровень зарплаты хороший, но хочется большего.
                            </p>
                        </div>
                    </li>
                    <li>
                         <div className="employees__image-wrapper">
                             <img src={employeeSvg} alt="Отзыв"/>
                         </div>
                        <div className="employees__content-wrapper">
                            <h4 className="employees__title">Иван</h4>
                            <p className="employees__subtitle">Сборщик продукции</p>
                            <p className="employees__text">Повезло мне устроиться в Акваарт. Нравится мне и специфика работы ( я сборщик), и оплата труда.
                                Рабочие моменты бывают, а вот конкретно конфликты нет. С коллегами отношения норм, с начальством тоже. Работаю на пятидневке, с законным перерывом на обед.
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    );
};

export default Employees;
