import React from 'react';
import yandexSvg from "../../images/yandex.svg";
import googleSvg from "../../images/google.svg";
import gisSvg from "../../images/gis.svg";
import hhSvg from "../../images/hh.svg";
import truthSvg from "../../images/truth.svg";
import unionSvg from "../../images/union.svg";

const Stars = () => {
    return (
        <section className="stars" id="second">
            <div className="stars__wrapper">
                <a href="https://yandex.ru/maps/org/akvaart_grup/111428315946/?ll=30.244829%2C59.952430&z=13" className="stars__item">
                    <img src={yandexSvg} alt="Yandex" />
                </a>
                <a href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x4696370dc7642c95:0x2c4dfc470ca390a0?source=g.page.m.we._" className="stars__item">
                    <img src={googleSvg} alt="Google" />
                </a>
                <a href="https://2gis.ru/spb/firm/5348552838608263" className="stars__item">
                    <img src={gisSvg} alt="2gis" />
                </a>
                <a href="https://spb.hh.ru/employer/14208" className="stars__item">
                    <img src={hhSvg} alt="Headhunter" />
                </a>
                <a href="https://pravda-sotrudnikov.net/company/akvaart/" className="stars__item">
                    <img src={truthSvg} alt="Правда сотрудников" />
                </a>
                <a href="https://otrude.xyz/employers/93923" className="stars__item">
                    <img src={unionSvg} alt="О труде" />
                </a>
            </div>
        </section>
    );
};

export default Stars;
